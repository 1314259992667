@import './reset.css';

body {
   font-size: 100%;
   /* font-family: 'Montserrat', sans-serif; */
   font-family: 'Roboto', sans-serif;
   color: #222;
}

.somenteDesktop{
   display: unset;
}
.somenteMobile{
   display: none;
}

.limite{
   max-width:1300px;
   min-width: 1024px;
   margin: 0 auto;
}

.oc-linhaDivSep{
   width:200px;
   margin:0 auto;
   height: 5px;
   background-color: #4bbbb5;
}

/* BOTÕES */
.oc-btn-arrowDown{
   width:68px;
   height:68px;
   background-color: transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   padding:0;
   border:none;
   outline: none;
   cursor: pointer;
}

/* MARGENS */
.mt-20{ margin-top:20px; }
.mt-40{ margin-top:40px; }

.mr-20{ margin-right: 20px; }
.mr-40{ margin-right: 40px; }

.ml-20{ margin-left: 20px; }
.ml-40{ margin-left: 40px; }

/* ESPAÇAMENTOS */
.pl-5em { padding-left:5em; }
.pr-5em { padding-right: 5em; }


/* TEXTO */
.text-center{ text-align: center; }
.text-justify{ text-align: justify; }

.blocoTexto p{
   line-height: 1.4em;
   font-size: 1rem;
}
.blocoTexto p:not(:last-child){
   margin-bottom: 20px;
}

.ol-lista-normal{
   list-style: disc;
   margin-left: 20px;
   padding-left: 20px;
}
.ol-lista-normal li{
   line-height: 1.4em;
   font-size: 1rem;
}
.ol-lista-normal li:not(:last-child){
   margin-bottom: 15px;
}

/* TITULOS */
h1.oc-titulo{
   font-size: 3rem;
   line-height: 1.4em;
   font-weight: 400;
   margin-bottom: 30px;
}
h2.oc-titulo{
   font-size: 2rem;
   line-height: 1.4em;
   font-weight: 700;
   margin-bottom: 30px;
}

/* LOADING */
.oc-loading .icone{
   font-size: 20px;
   animation: spinLoader 1.5s linear infinite;
}
@keyframes spinLoader {
   0% { transform: rotate(0deg); }
   50% { transform: rotate(180deg); }
   100% { transform: rotate(360deg); }
}



/* FLEX */
.oc-flex{
   display: flex;   
}



/* LOADING */
.oc-loading-full{
   position: fixed;
   top:0;
   bottom: 0;
   width:100%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.oc-loader{
   border: 3px solid #f3f3f3;
   border-top: 3px solid #4bbbb5;
   border-radius: 50%;
   width: 50px;
   height: 50px;
   animation: spinLoader 1.5s linear infinite;
}
@keyframes spinLoader {
   0% { transform: rotate(0deg); }
   50% { transform: rotate(180deg); border-top-color: #7997d3; }
   100% { transform: rotate(360deg); border-top-color: #4bbbb5; }
 }



/* MOBILE */
@media (max-width:900px){
   .somenteDesktop{
      display: none;
   }
   .somenteMobile{
      display: unset;
   }   

   .limite{
      max-width:1300px;
      min-width: 100%;
      width:100%;
      margin: 0 auto;
      padding:0 15px;
   }

   /* TITULOS */
   h1.oc-titulo{
      font-size: 2.4rem;
   }

   /* ESPAÇAMENTOS */
   .pl-5em { padding-left:0; }
   .pr-5em { padding-right: 0; }
}